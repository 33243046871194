<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <fieldset>
              <v-legend
                :title="'Employees'"
                :description="
                  'Here are all the employees at your company, including terminated ones.'
                "
              ></v-legend>
              <div class="row mb-5">
                <div class="col-lg-2">
                  <input
                    id="inline-form-input-name"
                    type="text"
                    placeholder="Employee ID"
                    class="mb-3 mr-sm-3 mb-sm-0 form-control"
                  />
                </div>
                <div class="col-lg-3">
                  <input
                    id="inline-form-input-name"
                    type="text"
                    placeholder="Employee Name"
                    class="mb-3 mr-sm-3 mb-sm-0 form-control"
                  />
                </div>
                <div class="col-lg-3">
                  <select class="mb-3 mr-sm-3 mb-sm-0 custom-select">
                    <option value="">Please select designation</option>
                    <option
                      v-for="(designItem, index) in getDesignation"
                      :key="index"
                      :value="designItem.id"
                    >
                      {{ designItem.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn mr-3 btn-success"
                  >
                    <i class="fa fa-search"></i>
                    Search
                  </button>
                </div>

                <div class="col-lg-2">
                  <router-link
                    :to="{ name: 'employee.add' }"
                    style="width: 100%"
                    class="btn mr-3 btn-primary"
                  >
                    <i class="fa fa-plus"></i>
                    New employee
                  </router-link>
                </div>
              </div>

              <div class="v-data-table v-data-table--fixed-header theme--light">
                <div class="v-data-table__wrapper">
                  <table class="table table-hover">
                    <thead>
                      <tr style="background-color: #f2f4f5">
                        <th width="80px"></th>
                        <th width="100px" class="text-right">#Emp.ID</th>
                        <th>Full Name</th>
                        <th>Contact</th>
                        <th>Designation</th>
                        <th>Hire Date</th>
                        <th>Contract</th>
                        <th>Status</th>
                        <th width="100px"></th>
                      </tr>
                      <tr v-if="loader">
                        <td colspan="12" class="text-center">
                          <data-loader
                            :type="'dark'"
                            :padding="10"
                          ></data-loader>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="12"
                          class="text-center"
                          v-if="getEmployees.length < 1 && !loader"
                        >
                          <div class="p-6">
                            <span>No employee(s) found</span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody v-if="getEmployees.length > 0 && !loader">
                      <tr
                        style="line-height: 40px"
                        v-for="(employeeItem, index) in getEmployees"
                        :key="index"
                      >
                        <td>
                          <a href="/">
                            <img
                              :src="employeeItem.avatar"
                              width="40px"
                              alt="username"
                              class="rounded-circle mx-auto d-block"
                            />
                          </a>
                        </td>
                        <td class="text-right">
                          {{ employeeItem.employee_id }}
                        </td>
                        <td>{{ employeeItem.full_name }}</td>
                        <th>
                          {{ employeeItem.contact_number }}
                        </th>
                        <td>
                          {{
                            employeeItem.contract != ""
                              ? employeeItem.contract.designation_name
                              : ""
                          }}
                        </td>
                        <td>
                          {{ employeeItem.joined_date }}
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'employee.edit.contract',
                              params: { id: employeeItem.id }
                            }"
                          >
                            <span
                              class="badge mr-1"
                              :class="
                                employeeItem.contract != ''
                                  ? 'badge-' +
                                    employeeItem.contract.status_badge
                                  : 'badge-dark'
                              "
                              v-b-tooltip
                              :title="
                                employeeItem.contract != ''
                                  ? employeeItem.contract.status
                                  : 'No Contract ! Please add'
                              "
                            >
                              {{
                                employeeItem.contract != ""
                                  ? employeeItem.contract.start_date +
                                    " - " +
                                    employeeItem.contract.end_date
                                  : "No Contract"
                              }}
                            </span>
                          </router-link>
                        </td>
                        <td>
                          <span
                            class="badge mr-1"
                            :class="'badge-' + employeeItem.status.badge"
                            >{{ employeeItem.status.status }}</span
                          >
                        </td>
                        <td class="text-right pr-0">
                          <!-- <a
                            href="#"
                            class="btn btn-icon btn-light btn-sm"
                            v-b-tooltip
                            title="Send Email"
                          >
                            <i class="fa fa-envelope text-info text-sm"></i>
                          </a> -->

                          <router-link
                            :to="{
                              name: 'employee.edit.personal',
                              params: { id: employeeItem.id }
                            }"
                            href="#"
                            class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                            v-b-tooltip
                            title=""
                          >
                            <i class="fa fa-arrow-right text-dark text-sm"></i>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import Legend from "@/includes/content/widgets/Legend";
import {
  FETCH_DESIGNATIONS,
  FETCH_EMPLOYEES
} from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: false
    };
  },
  components: {
    "data-loader": dataLoader,
    "v-legend": Legend
  },
  mounted() {
    this.loader = true;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Employees List" }]);
    this.$store.dispatch(FETCH_DESIGNATIONS);
    this.$store
      .dispatch(FETCH_EMPLOYEES)
      .then(() => {
        this.loader = false;
      })
      .catch(() => {
        this.loader = false;
      });
  },
  computed: {
    ...mapGetters(["getDesignation", "getEmployees"])
  }
};
</script>
